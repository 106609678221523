import {useState} from 'react'; 
import './game1.css';

const Game1 = () => {

    const [widgets, setWidgest] = useState<string[]>([]);

    const handleOnDrag = (e: React.DragEvent, widgetType: string) => {
        e.dataTransfer.setData("widgetType", widgetType);
        console.log(widgetType);

    }

    const handleOnDrop = (e: React.DragEvent) => {
        const widgetType = e.dataTransfer.getData("widgetType") as string;
        console.log("widgetType", widgetType);
        setWidgest([...widgets, widgetType]);
        console.log("drop over2");

    }

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        console.log("drop over3");
    }

    return (
        <div className="page">
            <h1>
            Игры
            </h1>
            {/* <div>
                <div className="widget" draggable onDragStart={(e) => handleOnDrag(e, "Widget A")}>
                    Widget A
                </div>
                <div className="widget" draggable onDragStart={(e) => handleOnDrag(e, "Widget B")}>
                    Widget B
                </div>
                <div className="widget" draggable onDragStart={(e) => handleOnDrag(e, "Widget C")}>
                    Widget C
                </div>
            </div>
            <div className="area" onDrop={(e) => handleOnDrop(e)} onDragOver={(e) => handleDragOver(e)}>
                {widgets.map((widget, index) => (
                    <div className="dropped-widget" key={index}>
                        {widget}
                    </div>
                ))}

            </div> */}
            <h2>Пока в стадии разработки</h2>

        </div>  
    )
}

export default Game1;