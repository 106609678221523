// import './lk.css';

const Lk = () => {
    return(
        <div className="page">
            <h1>
            Личный кабинет
            </h1>
            <h2>Пока в стадии разработки</h2>
        </div>        
    )
}

export default Lk;